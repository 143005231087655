import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb } from '../components/Section'
import { Link } from "gatsby"
import styled from 'styled-components'
import CaseStudies from '../components/CaseStudies'
import BreakpointDown from '../components/Media/BreakpointDown'

const CaseStudiesTitle = styled.h1`
    font-size: 218px;
    text-transform: uppercase;
    position: absolute;
    top: 189px;
    left: 0;
    right: 0;
    text-align: center;
    opacity: 0.06;
    @media(max-width:1600px){
      font-size:110px;
      top: 189px;
    }
    ${BreakpointDown.xl`
      top: 94px;
      text-align:center;
      font-size:90px;
 `}
    ${BreakpointDown.md`
      font-size:40px;
 `}
`

const CaseStudiesWrapper = styled.div`
 section{
   padding-top:0;
   box-shadow:none;
   >div:not(.container){
     display:none;
   }
 }
`
function CaseStudiesPage() {
  return (
    <Layout pageName="case-studies">
      <SEO title="Case Studies" />
      <Section pt="160px" pb="95px" xpt="90px" xpb="60px" mpt="70px" mpb="40px" bgColor="#F8F8F8">
        <CaseStudiesTitle className="h1">Case Studies</CaseStudiesTitle>
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link><Link to="/#">Case Studies</Link>
          </BreadCrumb>
        </div>
      </Section>
      <CaseStudiesWrapper>
        <CaseStudies />
      </CaseStudiesWrapper>
    </Layout>
  )
}
export default CaseStudiesPage