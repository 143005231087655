import React from 'react'
import styled from 'styled-components'
import { Section, Tags } from '../Section'
import PrimaryLinkButton from '../Button/PrimaryLinkButton'
import SecondaryLinkButton from '../Button/SecondaryLinkButton'
import { StaticImage } from "gatsby-plugin-image"
import BreakpointDown from '../Media/BreakpointDown'
import { Link } from 'gatsby'

const BoxShadowWrapper = styled(Section)`
    box-shadow: 0px 20px 85px rgba(0, 0, 0, 0.20);
    z-index:2;
  `

const CaseStudyTitle = styled.div`
  font-size:80px;
  line-height:50px;
  font-weight:900;
  text-transform: uppercase;
  opacity: 0.06;
  margin-bottom:70px;
  @media(min-width:2200px){
    margin-bottom:50px;
  }
  @media(max-width:1024px){
    text-align:center;
  }
  ${BreakpointDown.lg`
   margin-bottom:30px;
 `}  
 ${BreakpointDown.md`
   font-size:40px;
   font-weight:900;
   opacity: 0.06;
   margin-bottom:10px;
   text-align: center;
 `}
`
const CaseStudyWrappaer = styled.div`
  display:flex;
  margin-left:-15px;
${BreakpointDown.xl`
  margin-right:-15px;
`}
  flex-wrap:wrap;
  position:relative;
`
const LeftItem = styled.div`
  flex: 0 0 100%;
  max-width:100%;
  width:100%;

  ${BreakpointDown.lg`
    padding-left:15px;
    padding-right:15px; 
  `}
  ${BreakpointDown.md`
    text-align: center;
  `}

  @media(min-width:1025px){
    flex: 0 0 320px;
    width: 320px;
  }
`
const LeftItemInner = styled.div`
  background:#fff;
  max-width:330px;
  padding: 10px 20px 10px 50px;
    max-width: 400px;
    position: sticky;
    top: 120px;
    margin-top:200px;
    margin-right: -67px;
  @media( min-width:2400px){
    max-width:490px;
  }
  @media(max-width:1440px){
    margin-right: -35px; 
  }
  @media(max-width:1280px){
    padding: 10px 15px;
    max-width:290px;
  }
  @media(max-width:1024px){
    max-width:100%;
    margin-top:0px;
  }
  ${BreakpointDown.lg`
    padding: 10px 0px; 
    margin-top:0px;
  `}

`
const LeftItemSubTitle = styled.div` 
  text-transform: uppercase;
  font-size:12px;
  font-weight:900;
  letter-spacing: 1.8px;
  line-height: 1;
  margin-bottom: 8px;
`
const LeftItemHeading = styled.div`
  margin-bottom:0;
`
const MiddleItem = styled.div`
  flex: 0 0 70%;
  max-width:70%;
  margin-right:auto;
  order:2;
  ${BreakpointDown.xl`
     flex: 0 0 68%;
     max-width:68%;
  `}
  @media(max-width:1024px){
    flex: 0 0 100%;
    max-width:100%; 
  }
  ${BreakpointDown.lg`
    flex: 0 0 100%;
    max-width:100%;
    padding-left:15px;
    padding-right:15px;
  `}
 `
const BorderWrapper = styled.div`
  border: 6px solid #EEEEEE;
  padding:140px;
  display:flex;
  align-items: center;
  flex-wrap:wrap;
  & + & {
    margin-top:60px;
    ${BreakpointDown.md`
   margin-top:30px;
  `}
  }
  @media(max-width:1440px){
    padding: 35px 92px 35px 40px;
  }
  @media(max-width:1024px){
    padding: 35px 35px 35px;
  }
  ${BreakpointDown.md`
       padding: 15px 15px 15px
  `}
`
const ImgItem = styled.div`
    flex: 0 0 100%;
    max-width:100%;
    width:100%;
    order:2;
  @media(min-width:1025px){
    flex: 0 0 25%;
    max-width:25%;
    position:absolute;
    right:0;
    order:3;   
  }
`
const MiddleItemHeading = styled.div`
 
`
const TagsWrapper = styled.div`
  margin-bottom:20px;
`
const ImgWrapper = styled.div`
 /* max-width:490px; */
 width:100%;
 position:relative;
 padding:10px 0;
 background:#fff;
 margin-left:auto;

 ${BreakpointDown.md`
  .gatsby-image-wrapper{
    width:100%;
    max-width:100%;
  }
  
 `}
`

const MiddleContentWrapper = styled.div`
    padding-right:180px;
    margin-left:auto;
    margin-right:auto;
    @media(max-width:1280px){
      padding-right:180px;
    }
    @media(max-width:1024px){
      order:3;
    }


    ${BreakpointDown.xl`
      padding-right:0;
    `}


    .btn{
      margin-top:30px;
      ${BreakpointDown.lg`
       margin-top:10px;
      `}


      ${BreakpointDown.md`
       margin-top:0px;
      `}
    }
`

const CaseStudies = () => {
  return (
    <BoxShadowWrapper pb="120px" xpb="60px" mpb="30px" pt="4px" xpt="60px" mpt="20px" xxpt="10px" xxpb="150px">
      <CaseStudyTitle>Case Studies</CaseStudyTitle>
      <div className="container">
        <CaseStudyWrappaer>
          <LeftItem>
            <LeftItemInner>
              <LeftItemSubTitle>Case Studies</LeftItemSubTitle>
              <LeftItemHeading className="h1">Learn More About Real Solutions We’ve Provided for Real Clients</LeftItemHeading>
              <Link to="/case-studies"><PrimaryLinkButton text="VIEW ALL CASE STUDIES" /></Link>
            </LeftItemInner>
          </LeftItem>
          <MiddleItem>
            <BorderWrapper>
              <MiddleContentWrapper>
                <MiddleItemHeading className="h4">Matthews Specialty Vehicles – Greensboro, NC</MiddleItemHeading>
                <TagsWrapper>
                  <Tags>Building Remodel – Updated Data/Fiber Cabling</Tags>
                  <Tags>Network Design, Installation, & Configuration</Tags>
                  <Tags>Phone & Video/Teleconferencing Solutions</Tags>
                  <Tags>Surveillance Installation</Tags>
                  <Tags>Digital Signage</Tags>
                </TagsWrapper>
                <p>Matthew Specialty Vehicles needed several technology upgrades for their facility in Greensboro, NC, and WYN was happy to help! We removed outdated network cabling, and then installed high-speed fiber and CAT6 infrastructure cabling for phones, data, and surveillance. We placed data racking/power management in determined locations, and installed/configured new phones & video/teleconferencing equipment. Finally, we installed surveillance NVR/cameras, and placed thermal temperature monitoring solutions at key building entrances.</p>
                <Link to="/matthews-specialty-vehicles-greensboro-nc"><SecondaryLinkButton text="Read Full Story" /></Link>
              </MiddleContentWrapper>
              <ImgItem>
                <ImgWrapper>
                  <StaticImage
                    src="../../images/case-study-2.jpg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Case Study"
                    placeholder="blurred"
                  />
                </ImgWrapper>
              </ImgItem>
            </BorderWrapper>
            <BorderWrapper>
              <MiddleContentWrapper>
                <MiddleItemHeading className="h4">MCHP Medical Clinics – Bakersville, NC</MiddleItemHeading>
                <TagsWrapper>
                  <Tags>Access Control Hardware Installation & Configuration</Tags>
                </TagsWrapper>
                <p><strong>Key Issue:</strong> Entries/exits used for employee and vendor access need to be regulated for safety and efficiency.</p>
                <p><strong>Services Provided:</strong> Access control technology installed for 6 medical/dental clinic locations.</p>
                <p><strong>Solution Overview:</strong> Access control is networked such that all entries and exits are managed from one central computer.</p>
                <p><strong>Key Results:</strong> The system has improved security, productivity, and ease of access control management.</p>
                <Link to="/mchp-medical-clinics-bakersville-nc"><SecondaryLinkButton text="Read Full Story" /></Link>
              </MiddleContentWrapper>
              <ImgItem>
                <ImgWrapper>
                  <StaticImage
                    src="../../images/case-study-2.jpg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Case Study"
                    placeholder="blurred"
                  />
                </ImgWrapper>
              </ImgItem>
            </BorderWrapper>
            <BorderWrapper>
              <MiddleContentWrapper>
                <MiddleItemHeading className="h4">City Transfer and Storage – High Point, NC</MiddleItemHeading>
                <TagsWrapper>
                  <Tags>New VOIP Phone System & SIP Lines installation</Tags>
                  <Tags>Fiber Cable installation</Tags>
                </TagsWrapper>
                <p>The legacy phone system being used by City Transfer and Storage was no longer supported. They came to WYN looking to upgrade their phone system to a sustainable modern technology solution which would maximize their return on investment well into the future. The chosen technology allows associates to connect their phone, PC, and smartphone for greater onsite and offsite productivity.</p>
                <Link to="/city-transfer-and-storage-high-point-nc"><SecondaryLinkButton text="Read Full Story" /></Link>
              </MiddleContentWrapper>
              <ImgItem>
                <ImgWrapper>
                  <StaticImage
                    src="../../images/case-study-2.jpg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Case Study"
                    placeholder="blurred"
                  />
                </ImgWrapper>
              </ImgItem>
            </BorderWrapper>
            <BorderWrapper>
              <MiddleContentWrapper>
                <MiddleItemHeading className="h4">Halifax County Health Department – Halifax, NC</MiddleItemHeading>
                <TagsWrapper>
                  <Tags>Human Temperature Monitoring Technology Installation</Tags>
                </TagsWrapper>
                <p>The Halifax County Health Department needed an effective way to record human body temperatures remotely. The solution needed to be able to provide an accurate HIPAA report, while also being able to determine whether the individual was masked. If not, a verbal reminder would activate, and would also enable remote entry point viewing through a PC or smartphone.</p>
                <Link to="/halifax-county-health-department-halifax-nc"><SecondaryLinkButton text="Read Full Story" /></Link>
              </MiddleContentWrapper>
              <ImgItem>
                <ImgWrapper>
                  <StaticImage
                    src="../../images/case-study-2.jpg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Case Study"
                    placeholder="blurred"
                  />
                </ImgWrapper>
              </ImgItem>
            </BorderWrapper>
            <BorderWrapper>
              <MiddleContentWrapper>
                <MiddleItemHeading className="h4">Perquimans County Schools – Hertford, NC</MiddleItemHeading>
                <TagsWrapper>
                  <Tags>Video Surveillance System Installation</Tags>
                </TagsWrapper>
                <p>The legacy camera & surveillance system being used by Perquimans County Schools had aged out and was no longer supported. The client came to WYN Technologies in need of a video surveillance solution which would provide effective monitoring of school properties, both inside and outside.</p>
                <Link to="/perquimans-county-schools-hertford-nc"><SecondaryLinkButton text="Read Full Story" /></Link>
              </MiddleContentWrapper>
              <ImgItem>
                <ImgWrapper>
                  <StaticImage
                    src="../../images/case-study-2.jpg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Case Study"
                    placeholder="blurred"
                  />
                </ImgWrapper>
              </ImgItem>
            </BorderWrapper>
          </MiddleItem>
        </CaseStudyWrappaer>
      </div>
    </BoxShadowWrapper>
  );
}

export default CaseStudies;